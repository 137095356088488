var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { staticClass: "about" },
    [
      _c("h1", { staticClass: "text--white" }, [
        _vm._v("Plaid Account Integration")
      ]),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { staticClass: "mt-4", attrs: { cols: "12" } },
            [
              _c(
                "b-card",
                { staticClass: "h-100" },
                [
                  _c("b-card-title", [_vm._v("Current Accounts")]),
                  _c(
                    "b-overlay",
                    { attrs: { show: _vm.loading } },
                    [
                      _c(
                        "b-table-simple",
                        {
                          staticClass:
                            "table-striped table-condensed table-sm table-hover"
                        },
                        [
                          _c(
                            "b-thead",
                            [
                              _c(
                                "b-tr",
                                [
                                  _c("b-th", [_vm._v("Bank")]),
                                  _c("b-th", { staticClass: "text-left" }, [
                                    _vm._v("Account")
                                  ]),
                                  _c("b-th", [_vm._v("Status")])
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm.items && _vm.items.length > 0
                            ? _c(
                                "b-tbody",
                                _vm._l(_vm.items, function(i) {
                                  return _c(
                                    "b-tr",
                                    {
                                      key: i.id ? i.id : "pi" + i.plaiditem_id
                                    },
                                    [
                                      _c("b-td", [
                                        _vm._v(_vm._s(i.institution))
                                      ]),
                                      _c("b-td", { staticClass: "text-left" }, [
                                        _vm._v(_vm._s(i.name))
                                      ]),
                                      _c("b-td", [
                                        i.needsauth
                                          ? _c(
                                              "span",
                                              [
                                                _c("b-icon-bug-fill", {
                                                  staticClass: "text-danger"
                                                }),
                                                _vm._v(" Error ")
                                              ],
                                              1
                                            )
                                          : !i.type
                                          ? _c(
                                              "span",
                                              [
                                                _c(
                                                  "b-icon-cloud-download-fill",
                                                  {
                                                    staticClass: "text-warning"
                                                  }
                                                ),
                                                _vm._v(" Synchronizing ")
                                              ],
                                              1
                                            )
                                          : _c(
                                              "span",
                                              [
                                                _c("b-icon-check-circle-fill", {
                                                  staticClass: "text-success"
                                                }),
                                                _vm._v(" Connected ")
                                              ],
                                              1
                                            )
                                      ])
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            : _c(
                                "b-tbody",
                                [
                                  _c(
                                    "b-tr",
                                    [
                                      _c("b-td", { attrs: { colspan: "3" } }, [
                                        _vm._v("No accounts yet.")
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: {
                        size: "sm",
                        variant: "primary",
                        disabled: _vm.loading
                      },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.load.apply(null, arguments)
                        }
                      }
                    },
                    [_vm._v("Refresh")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { staticClass: "mt-4", attrs: { cols: "12" } },
            [
              _c(
                "b-card",
                { staticClass: "h-100" },
                [
                  _c("b-card-title", [_vm._v("Add a New Plaid Account")]),
                  _c(
                    "b-row",
                    { staticClass: "text-left" },
                    [
                      _c("b-col", [
                        _c("p", [
                          _vm._v(
                            "Plaid.com allows us to safely and securely retrieve transactions directly from your checking and credit card accounts. Plaid interacts directly with your banks authentication system, allowing us to retrieve data without storing your credentials or account numbers."
                          )
                        ]),
                        _c("p", [
                          _vm._v(
                            "Please follow the steps below to add a new account:"
                          )
                        ]),
                        _c("ol", [
                          _c("li", [
                            _vm._v(
                              "Click the 'Add a New Bank Account' button below."
                            )
                          ]),
                          _c("li", [
                            _vm._v(
                              "Select your bank from the list or search by name. If your institution is not listed, it may not be supported yet. Please "
                            ),
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: "mailto:devteam@aimitservices.com"
                                }
                              },
                              [_vm._v("contact us")]
                            ),
                            _vm._v(".")
                          ]),
                          _c("li", [
                            _vm._v(
                              "Follow the authentication and authorization prompts provided by your financial institution."
                            )
                          ]),
                          _c("li", [
                            _vm._v(
                              "If prompted to select accounts, we likely only need your checking/savings and credit card accounts."
                            )
                          ]),
                          _c("li", [
                            _vm._v(
                              "Upon success, the newly authorized account should appear in your list. Click the refresh button to check the status. Accounts will synchronize within a few minutes."
                            )
                          ]),
                          _c("li", [
                            _vm._v("If you receive any errors, please "),
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: "mailto:devteam@aimitservices.com"
                                }
                              },
                              [_vm._v("let us know")]
                            ),
                            _vm._v(".")
                          ])
                        ])
                      ])
                    ],
                    1
                  ),
                  _c("PlaidLink", {
                    attrs: {
                      "company-id": _vm.companyId,
                      "button-title": "Add a New Bank Account"
                    },
                    on: { refresh: _vm.load }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }